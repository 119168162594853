import React, { Component } from "react"
import axios from "axios"

class Search extends Component {
  state = {
    JobList: [],
    searchResults: [],
    isLoading: true,
    isError: false,
    departments: [],
    selectedDepartment: "All",
    selectedOffice: "All",
    genericListing: {},
  }
  /**
   * React lifecycle method to fetch the data
   */
  async componentDidMount() {
    axios
      .get(
        "https://boards-api.greenhouse.io/v1/boards/mullenlowe/jobs?content=true"
      )
      .then(result => {
        const jobsData = result.data.jobs

        jobsData.forEach(function (item, index) {
          if (item.offices.length < 1) {
            item["short_city"] = "Remote"
            if (typeof window !== "undefined" && window.innerWidth >= 768) {
              item["short_city"] = "Remote"
            } else {
              item["short_city"] = "RMT"
            }
          } else if (item.offices.length > 1) {
            item["location"]["name"] = "Multiple Offices"
          }
          item["department_string"] = item.departments[0]["name"].replace(
            "MullenLowe",
            ""
          )
        })

        // get list of unique depts, then filter out the remote listing which has a department of MullenLowe
        const allDepartments = [
          ...new Set(jobsData.map(result => result.department_string)),
        ].filter(item => item !== "MullenLowe")
        const genericListing = jobsData.find(
          job => job.short_city === "Remote" || job.short_city === "RMT"
        )
        const jobsDataNoGeneric = jobsData.filter(
          job => job.department_string !== ""
        )

        this.setState({
          departments: allDepartments,
          JobList: jobsDataNoGeneric,
          searchResults: jobsDataNoGeneric,
          genericListing,
        })
      })
      .catch(err => {
        this.setState({ isError: true })
        console.log("====================================")
        console.log(`Something bad happened while fetching the data\n${err}`)
        console.log("====================================")
      })
  }

  filterListings = (department, office) => {
    const { JobList } = this.state

    const searchResults = JobList.filter(
      job => department === "All" || job.department_string === department
    ).filter(job => office === "All" || job.short_city === office)

    this.setState({ searchResults })
  }

  handleSubmit = e => {
    e.preventDefault()
  }

  randomNumber() {
    const rand =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    return rand
  }

  handleDepartmentChange = selectedDepartment => {
    this.setState({ selectedDepartment })
    this.filterListings(selectedDepartment, this.state.selectedOffice)
  }

  handleOfficeChange = selectedOffice => {
    this.setState({ selectedOffice })
    this.filterListings(this.state.selectedDepartment, selectedOffice)
  }

  render() {
    const { searchResults } = this.state
    const departments = [
      ...new Set(searchResults.map(result => result.department_string)),
    ].filter(item => item !== "MullenLowe")

    let departmentSelects = this.state.departments.map(department => {
      return {
        value: department,
        label: department,
      }
    })

    departmentSelects.unshift({ value: "All", label: "All" })

    return (
      <div className="container jobs">
        <h2 className="descriptor jobs__open-title">Open Positions</h2>
        <div className="jobs__results">
          {departments.map(department => {
            // if (department === '') return

            return (
              <div key={this.randomNumber()}>
                <p className="jobs__department">{department}</p>
                <ul>
                  {searchResults.map(item => {
                    if (item.department_string !== department) return null

                    let title = item.title
                    let newtitle = title.toString().replace(/&amp;/g, "&")

                    return (
                      <li
                        className="jobs__listing"
                        data-id={item.id}
                        key={this.randomNumber()}
                        tabIndex="-1"
                        role="group"
                      >
                        <a
                          className="jobs__link"
                          href={item.absolute_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <p className="jobs__title">{newtitle}</p>
                          {/* spacer for hover to account for differences in italic font width */}
                          {/* <div className="spacer"></div> */}
                          <p className="jobs__city">{item.location.name}</p>
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
            )
          })}
          <div className="jobs__listing jobs__listing--generic">
            <a
              className="jobs__link"
              href={this.state.genericListing?.absolute_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="jobs__title jobs__title--generic">
                {this.state.genericListing?.title}
              </p>
              <p className="jobs__city">Remote</p>
            </a>
          </div>
        </div>
      </div>
    )
  }
}
export default Search
