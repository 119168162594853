import * as React from "react"
import { useState, useEffect} from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Search from "../../components/SearchContainer"
import { graphql } from "gatsby"
import Features from "../../components/features"
import AboutIntro from "../../components/aboutIntro"


const Jobs = ({ location, data }) => {
  const benefits = data.sanityJobsPage.benefits;

  const handleClickChecker = (e) => {
    // e.target.classList.remove('active');
    const el = document.querySelector('.info-modal');
    el.classList.remove("active");
    console.log('clicked');
    return false;
  }

  useEffect(() => {
    document.addEventListener("keydown", handleClickChecker, false);

    return () => {
      document.removeEventListener("keydown", handleClickChecker, false);
    };
  }, [handleClickChecker]);

  return (
    <Layout
      scheme="light"
      location={location}
      pageCategory="agency"
    >
      <Seo title="MullenLowe U.S. — Jobs" />
      <div className="dark">
        <AboutIntro
          copy={data.sanityJobsPage._rawIntroCopy}
          subhead={data.sanityJobsPage.subhead}
          image={data.sanityJobsPage.heroImage.image}
          altText={data.sanityJobsPage.heroImage.altText} />

        <Features 
          features={benefits}
          title="The Perks" 
        />
      </div>
      <div className="light">
        <Search />
      </div>

      <div className="info-modal active">
        <div className="modal-content">
        <p>It has come to our attention that individuals posing as representatives of our agency have been fraudulently using the MullenLowe name and engaging in recruitment activities.</p>
        <p>All legitimate communication regarding job opportunities at our agency will come through official channels such as LinkedIn and verified MullenLowe email addresses.</p>
  
        <p>Our recruitment process follows several steps, including in-person or video  interviews with multiple staff members, via Microsoft Teams or Zoom, and an official letter with any job offer. We do not request payment information for any reason.</p>
   
        <p>We urge you to remain vigilant as we work to take remedial action wherever possible.</p>

        <p>For the most up-to-date listing of our open positions, please visit the job page on our agency website.</p>

        <button className="modal-close" onClick={handleClickChecker}>Close</button>
        </div>

        <div className="modal-background"></div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    sanityJobsPage {
      _rawIntroCopy
      subhead
      heroImage {
        altText
        image {
          ...ImageWithPreview
        }
      }
      benefits {
        _key
        _rawDescription
        title
        image {
          altText
          image {
            ...ImageWithPreview
          }
        }
      }
    }
  }
`

export default Jobs